<template>
  <main-template>
    <v-row>
      <v-col cols="12">
        <v-col cols="12" class="pt-3 px-0">
          <div class="d-flex justify-space-between">
            <v-btn
              :to="{
                name: 'informativos.create',
              }"
              color="success"
              class="mr-2"
              >Criar Informativo</v-btn
            >
            <v-text-field
              v-model="search"
              class="ml-2 search-input"
              append-icon="mdi-magnify"
              label="Pesquisar..."
              solo
              autofocus
              dense
              single-line
              hide-details
            />
          </div>
        </v-col>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="avisos"
          :loading="carregandoAvisos"
          :items-per-page="10"
          hide-default-footer
          :custom-filter="dataTableFilter"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr color="primary" v-for="aviso in items" :key="aviso.id">
                <td>{{ aviso.id }}</td>
                <td>{{ aviso.titulo }}</td>
                <td>{{ aviso.contexto }}</td>
                <td>{{ aviso.tipo }}</td>
                <!-- <td>
                  <v-chip
                    style="width: 80px; justify-content: center"
                    :color="situacoes[aviso.status] ? situacoes[aviso.status].color : ''"
                    outlined
                    label
                    small
                  >
                    {{
                      aviso.status
                        ? situacoes[aviso.status]
                          ? situacoes[aviso.status].descricao
                          : "- - -"
                        : "- - -"
                    }}
                  </v-chip>
                </td> -->
                <td>
                  <v-chip
                    style="width: 80px; justify-content: center"
                    :color="situacoes[aviso.status] ? situacoes[aviso.status].color : ''"
                    outlined
                    label
                    small
                  >
                    {{ situacoes[aviso.status] ? situacoes[aviso.status].descricao : "- - -" }}
                  </v-chip>
                </td>
                <td width="25%">
                  <v-tooltip color="primary" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="cyan lighten-4"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => enableSubmit(aviso.id)"
                      >
                        <v-icon dark> mdi-bell-off </v-icon>
                      </v-btn>
                    </template>
                    <span>Parar/Iniciar notificação</span>
                  </v-tooltip>
                  <v-tooltip color="primary" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="verAviso(aviso.id, aviso)"
                      >
                        <span class="material-icons"> visibility </span>
                      </v-btn>
                    </template>
                    <span>Visualizar aviso</span>
                  </v-tooltip>
                  <v-tooltip color="primary" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        :to="{
                          name: 'informativos.edit',
                          params: { informativo_id: aviso.id },
                        }"
                      >
                        <v-icon small>fa fa-edit </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip color="primary" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => destroy(aviso.id)"
                      >
                        <v-icon small>fa fa-trash </v-icon>
                      </v-btn>
                    </template>
                    <span>Deletar</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <e-paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          :pageNumber="pageNumber"
          @changeActionResponse="loadData"
          :search="search"
        ></e-paginate>
        <e-modal-confirm
          :confirmDialog="confirmDialog"
          :objectModal="objectModal"
          @changeModalConfirm="enableSubmit"
          @changeActionResponse="notificar"
        >
        </e-modal-confirm>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-dialog v-model="dialog" width="374">
        <v-card v-if="!aviso" class="mx-auto" max-width="374">
          <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
            <v-skeleton-loader class="mx-auto" max-width="374" type="card"></v-skeleton-loader>
          </v-sheet>
        </v-card>

        <v-card v-else class="mx-auto" max-width="374">
          <v-card-title> {{ aviso ? aviso.titulo : "- - -" }} </v-card-title>
          <v-card-subtitle> {{ aviso ? aviso.contexto : "- - -" }} </v-card-subtitle>
          <v-card-text> </v-card-text>
          <v-card-text>
            <p>Professores envolvidos:</p>
            <p>
              {{ aviso && aviso.professores_nomes ? aviso.professores_nomes.join(", ") : "- - -" }}
            </p>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-actions>
            <v-btn @click="dialog = false" color="orange lighten-2" text> Fechar </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </main-template>
</template>

<script>
import { dataTableFilter } from "@/plugins/searchQuery";

export default {
  data() {
    return {
      dataTableFilter,
      avisos: [],
      objectModal: {
        title: "Atenção",
        message: "Tem certeza que deseja atualizar o status do informativo?",
      },
      confirmDialog: false,
      carregandoAvisos: false,
      search: "",
      pageNumber: 0,
      informativo_id: null,
      paginate: {
        current_page: 0,
        last_page: 0,
      },
      situacoes: {
        0: {
          descricao: "Ativo",
          color: "blue lighten-1",
        },
        1: {
          descricao: "Inativo",
          color: "red lighten-1",
        },
        // 2: {
        //   descricao: "Parado",
        //   color: "green lighten-1",
        // },
      },
      item: {
        aluno: "nomecompleto",
        turma: "descricao",
        franquia: "descricao",
      },
      tipos: ["alunos", "turmas", "configuracoes"],
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Titulo", value: "titulo" },
          { text: "Contexto", value: "contexto" },
          { text: "Tipo", value: "tipo" },
          { text: "Status", value: "status" },
          {
            text: "Ações",
            value: "actions",
            sortable: false,
            width: "200px",
          },
        ],
      },
      aviso: null,
      url_image_aviso: "",
      dialog: false,
    };
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  watch: {
    async search(val) {
      if (val.length > 2) {
        await this.loadData(0, val);
      }
      if (!val) {
        await this.loadData(0);
      }
    },
  },
  methods: {
    enableSubmit(informativo_id) {
      this.confirmDialog = !this.confirmDialog;
      this.informativo_id = informativo_id;
    },

    async loadData(pageNumber, query = null) {
      const response = await this.$services.informativosService.syncAllInformativos(
        pageNumber,
        query
      );
      this.paginate = response;
      this.avisos = response.data;
    },

    async notificar() {
      this.$loaderService.open("Atualizando aviso...");
      try {
        await this.$services.informativosService.pararNotificar(this.informativo_id);
        this.loadData(this.pageNumber);
        this.confirmDialog = !this.confirmDialog;
        this.$toast.success("Informativo atualizado com sucesso!");
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async destroy(informativo_id) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar este informativo?",
        confirmationCode: informativo_id,
        confirmationMessage: `Por favor, digite <strong>${informativo_id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a aviso");
              try {
                await this.$services.informativosService.destroy(informativo_id);
                this.loadData(this.pageNumber);
                this.$toast.success("Aviso deletado com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    async verAviso(informativo_id, aviso) {
      this.dialog = !this.dialog;
      this.$forceUpdate();
      // this.url_image_aviso = await this.$services.imageService.downloadImage(
      //   `notifiq/avisos/download-image/${informativo_id}`
      // );
      this.aviso = aviso;
    },
  },
  mounted() {
    this.loadData(this.pageNumber);
  },
};
</script>

<style></style>
