import Informativo from "@/Pages/Informativos/index.vue";
import NewEditInformativo from "@/Pages/Informativos/NewEditInformativos.vue";
// import AvisosPortalAluno from "@/Pages/Informativos/avisosPortalAluno.vue";

export default [
  {
    path: "/informativos/index",
    name: "informativos.index",
    component: Informativo,
  },
  // {
  //   path: "/informativos/portal",
  //   name: "informativos.portal",
  //   component: AvisosPortalAluno,
  // },
  {
    path: "/informativos/create",
    name: "informativos.create",
    component: NewEditInformativo,
  },
  {
    path: "/informativos/edit/:informativo_id",
    name: "informativos.edit",
    component: NewEditInformativo,
    props: { editing: true },
  },
];
