import Etapa from "@/Models/Etapa";
import Regra from "@/Models/Regra";
import Axios from "@/plugins/Axios";
import store from "@/store";

export class InformativosService {
  async syncAll() {
    const response = await Axios().get("informativos");
    const infos = response.data;
    return infos;
  }

  async create(dados) {
    const response = await Axios().post("informativos", dados);
    const info = response.data;
    return info;
  }

  async delete() {
    const response = await Axios().get(`informativos/deletar-todos`);
    const infor = response.data;
    return infor;
  }

  async syncAllInstrutores() {
    const response = await Axios().get("informativos/instrutores");
    const infos = response.data;
    return infos;
  }

  async createNotificarInstrutores(dados) {
    const response = await Axios().post("informativos/create-notificao-instrutores", dados);
    const info = response.data;
    return info;
  }

  async deleteNotificarInstrutores() {
    const response = await Axios().get(`informativos/deletar-todos-notificar-instrutores`);
    const infor = response.data;
    return infor;
  }

  async store(form) {
    const response = await Axios().post("informativos-professores/store", form);
    return response.data;
  }

  async syncAllInformativos(pageNumber, query) {
    const response = await Axios().get(
      `informativos-professores?page=${pageNumber}&query=${query}`
    );
    return response.data;
  }

  async find($aviso_id) {
    const response = await Axios().get(`informativos-professores/new-edit/${$aviso_id}`);
    return response.data;
  }

  async update(form, informativo_id) {
    const response = await Axios().post(`informativos-professores/update/${informativo_id}`, form);
    return response.data;
  }

  async destroy(informativo_id) {
    const response = await Axios().get(`informativos-professores/destroy/${informativo_id}`);
    return response.data;
  }

  async syncAllInformativosProfessores() {
    const response = await Axios().get("informativos-professores/show");
    const infos = response.data;
    return infos;
  }

  async pararNotificar(informativo_id) {
    const response = await Axios().get(
      `informativos-professores/stopNotification/${informativo_id}`
    );
    return response.data;
  }
}
export default new InformativosService();
