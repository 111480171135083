<template>
  <main-template>
    <v-alert
      v-if="countImagem"
      class="animate__animated animate__headShake"
      style="position: absolute; right: 15px; width: 420px; height: 50px"
      type="error"
      >Você só pode carregar somente uma <strong>IMAGEM</strong>.</v-alert
    >
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text :to="{ name: 'informativos.index' }">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Informativo
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex" cols="8">
        <v-select
          :items="selects"
          label="Selecione um tipo"
          v-model="form.selected"
          outlined
          :disabled="editing"
        ></v-select>
      </v-col>
      <v-col cols="2">
        <!-- <v-checkbox
          :false-value="1"
          :true-value="0"
          v-model="status"
          :label="
            situacoes[form.status || status] ? situacoes[form.status || status].descricao : '- - -'
          "
          color="success"
          :value="status"
          hide-details
        ></v-checkbox> -->
      </v-col>
      <!-- <v-col cols="2" v-if="form.selected">
        <v-checkbox
          v-if="form.selected !== 'Alunos' && !editing"
          v-model="selectAll"
          color="success"
          item-value="id"
          item-text="nome"
          :label="form.selected ? 'Todos' : 'Tipo vazio'"
        ></v-checkbox>
      </v-col> -->
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col cols="12">
            <ValidationProvider :name="prop" v-slot="{ errors }">
              <v-autocomplete
                v-model="form.items"
                :disabled="isUpdating"
                :items="items"
                filled
                chips
                color="blue-grey lighten-2"
                label="Selecione Professores"
                item-text="nome"
                item-value="id"
                multiple
                :error-messages="errors"
                persistent-hint
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove(data.item)"
                  >
                    <v-avatar color="primary" left>
                      <span style="color: #fff">{{
                        data.item.nome ? data.item.nome.substring(0, 1).toUpperCase() : "- - -"
                      }}</span>
                    </v-avatar>
                    {{ data.item.nome }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>

          <v-col cols="12">
            <ValidationProvider name="título" rules="required" v-slot="{ errors }">
              <v-text-field
                counter="25"
                hint="Você só pode digitar até 25 caracteres"
                label="Título"
                v-model="form.titulo"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="contexto" rules="required" v-slot="{ errors }">
              <v-textarea
                outlined
                name="input-7-1"
                label="Contexto da mensagem"
                v-model="form.contexto"
                hint="Hint text"
                :error-messages="errors"
              ></v-textarea>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-col cols="12">
          <v-row>
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              >Enviar Dados</v-btn
            >
          </v-row>
        </v-col>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
const STATUS_INITIAL = 0;
const STATUS_SAVING = 1;
const STATUS_SUCCESS = 2;
const STATUS_FAILED = 3;
export default {
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectAll: false,
      franquiaAtual: false,
      countImagem: false,
      autoUpdate: true,
      isUpdating: false,
      avisoUpload: null,
      selects: ["Professores"],
      base64Img: "",
      name: "Midnight Crew",
      form: {
        titulo: "",
        contexto: "",
        items: [],
        selected: "",
      },
      submittingForm: false,
      items: [],
      situacoes: {
        0: {
          descricao: "Ativo",
          color: "blue lighten-1",
        },
        1: {
          descricao: "Inativo",
          color: "red lighten-1",
        },
        2: {
          descricao: "Enviado",
          color: "green lighten-1",
        },
      },
      title: "The summer breeze",
      prop: "turma",
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "photos",
      status: 0,
    };
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    selected() {
      return this.form.selected;
    },
  },
  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
    selected(val) {
      this.selectAll = false;
      const functionDinamic = `load${val}`;
      if (!this.editing) {
        this.form.items = [];
      }
      this[functionDinamic]();
      return "ok";
    },
    selectAll() {
      this.form.items = this.items.map((item) => item.id);
      console.log(this.form.items);

      if (!this.selectAll) {
        this.showOpcoes = true;
        this.form.items = [];
      }
    },
  },
  methods: {
    remove(item) {
      const index = this.form.items.indexOf(item.id);
      if (index >= 0) this.form.items.splice(index, 1);
    },
    async submitForm() {
      this.$loaderService.open("Salvando aviso aguarde...");
      try {
        this.form.status = this.form.status === 2 ? this.form.status : this.status;
        if (this.editing) {
          await this.$services.informativosService.update(
            this.form,
            parseInt(this.$route.params.informativo_id, 10)
          );
          this.$toast.success("informativo editado com sucesso!");
          this.$router.push({ name: "informativos.index" });
        } else {
          const response = await this.$services.informativosService.store(this.form);
          this.$toast.success("Aviso criado com sucesso!");
        }
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    deleteImagem() {
      this.currentStatus = 0;
      this.avisoUpload = null;
    },
    reset() {
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
    filesChange(fileList) {
      if (!fileList.length) return;
      if (fileList.length > 1) {
        this.countImagem = true;
        setTimeout(() => {
          this.countImagem = false;
        }, 4000);
      }
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.avisoUpload = fileList[x];
        return fileList[x];
      });
      this.currentStatus = STATUS_SAVING;
    },
    async loadTurmas() {
      this.$loaderService.open("Carregando dados...");
      try {
        this.items = await this.$services.turmasService.syncAll();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async loadEscolas() {
      this.$loaderService.open("Carregando dados...");
      try {
        this.items = await this.$services.avisosService.franquias();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async loadAlunos() {
      this.$loaderService.open("Carregando dados...");
      try {
        this.items = await this.$services.avisosService.alunosFranquia();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async loadProfessores() {
      this.$loaderService.open("Carregando dados...");
      try {
        this.items = await this.$services.professoresService.syncAll();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async loadData() {
      this.$loaderService.open("Carregando dados...");
      try {
        if (this.editing) {
          const response = await this.$services.informativosService.find(
            parseInt(this.$route.params.informativo_id, 10)
          );
          this.form = response;
          if (this.form.selected == null) {
            this.form.selected = response.tipo;
          }
          this.status = parseInt(response.status, 10);
        }
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    // async uploadImage(id) {
    //   try {
    //     await this.$services.imageService.uploadImage(
    //       "notifiq/avisos/upload-image",
    //       this.avisoUpload,
    //       id
    //     );
    //   } catch (error) {
    //     this.$handleError(error);
    //   }
    // },
  },
  mounted() {
    this.reset();
    this.loadData();
  },
};
</script>

<!-- SASS styling -->
<style scoped>
.dropbox {
  outline: 2px dashed grey;
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px;
  position: relative;
  cursor: pointer;
}
.input-file {
  opacity: 0;
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}
.dropbox:hover {
  background: lightblue;
}
.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>
