<template>
  <v-system-bar v-if="informativoAtual" color="green lighten-4 lighten-5 pa-10">
    <v-card-text
      v-if="isProfessorAllowed"
      class="lineUp text-center text-h5 pt-4 font-weight-bold piscar"
    >
      <div>
        <v-menu open-on-hover top>
          <template v-slot:activator="{ on, attrs }">
            <div class="mt-5" outlined fab color="primary" small v-bind="attrs" v-on="on">
              {{ textoAlertaInstrutores }}
            </div>
          </template>
        </v-menu>
      </div>
    </v-card-text>
  </v-system-bar>
</template>

<script>
export default {
  props: {
    textoAlertaInstrutor: {
      type: String,
    },
    novoAlertaInstrutor: {
      type: Boolean,
    },
  },
  data() {
    return {
      textoAlertaInstrutores: "",
      editing: false,
      informativos: [],
      informativoIndex: 0,
      bloquearEdicao: true,
      professores: [],
      selectedProfessores: [],
      currentProfessorId: null,
      intervaloInformativo: null,
    };
  },
  computed: {
    isProfessorAllowed() {
      return this.currentProfessorId !== null;
    },
    informativoAtual() {
      return this.informativos[this.informativoIndex];
    },
  },
  watch: {
    informativoAtual(novoInformativo) {
      if (novoInformativo) {
        this.textoAlertaInstrutores = `${novoInformativo.titulo} ⇢ ${novoInformativo.contexto}`;
      }
    },
    novoAlertaInstrutor(valor) {
      if (valor === true) {
        this.editing = true;
      }
    },
  },
  mounted() {
    this.getRoleAtual();
    this.getInformativos();
    this.getProfessores();
    this.iniciarRotacaoAutomatica();
  },
  beforeDestroy() {
    this.pararRotacaoAutomatica();
  },
  methods: {
    async getRoleAtual() {
      try {
        const roleAtual = await this.$services.authService.getCurrentUser();
        this.currentProfessorId = roleAtual?.professor?.id;
        const rolesPermitidas = ["super-admin", "admin", "coordenador"];
        this.bloquearEdicao = !rolesPermitidas.includes(roleAtual.roles[0].name);

        if (this.bloquearEdicao) {
          this.$emit("bloquearBotaoNovo", true);
        } else {
          this.$emit("bloquearBotaoNovo", false);
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    async getInformativos() {
      try {
        const informativos =
          await this.$services.informativosService.syncAllInformativosProfessores();
        if (informativos.length) {
          this.ocultarBotaoNovo();
          this.informativos = informativos;
          this.updateTextoAlerta();
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    async getProfessores() {
      try {
        const professores = await this.$services.professoresService.syncAll();
        this.professores = professores.map((p) => ({ text: p.nome, value: p.id }));
      } catch (error) {
        this.$handleError(error);
      }
    },
    updateTextoAlerta() {
      const informativo = this.informativoAtual;
      if (informativo) {
        this.textoAlertaInstrutores = `${informativo.titulo} ⇢ ${informativo.contexto}`;
        this.selectedProfessores = Array.isArray(informativo.professores)
          ? informativo.professores
          : JSON.parse(informativo.professores || "[]");
      }
    },
    ocultarBotaoNovo() {
      this.$emit("situacaoBtnAlertaInstrutor", false);
    },
    iniciarRotacaoAutomatica() {
      this.intervaloInformativo = setInterval(() => {
        this.informativoIndex = (this.informativoIndex + 1) % this.informativos.length;
        this.updateTextoAlerta();
      }, 4000);
    },
    pararRotacaoAutomatica() {
      if (this.intervaloInformativo) {
        clearInterval(this.intervaloInformativo);
        this.intervaloInformativo = null;
      }
    },
  },
};
</script>
